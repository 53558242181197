import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import "./confirmation-record.scss"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header/header"
import YourOrder from "../containers/singleInformation/summary/your-order"
import { resetRegistration } from "../redux/registration/registrationAction"

const ConfirmationPaymentPage = () => {
    const dispatch = useDispatch()
    const { ticket, options } = useSelector(({ registrationReducer }) => registrationReducer)

    useEffect(() => {
        return () => {
            dispatch(resetRegistration())
        }
    }, [])

    const getVAT = () => {
        let total = ticket !== null ? parseFloat(ticket.packages_price) : 0

        options.forEach(option => {
            total = total + parseFloat(option.options_price)
        })

        return parseFloat(total).toFixed(2)
    }

    const getTotal = () => {
        let total = ticket !== null ? parseFloat(ticket.packages_price) : 0

        options.forEach(option => {
            total = total + parseFloat(option.options_price)
        })

        return parseFloat(total).toFixed(2)
    }

    return (
        <Layout main={false}>
            <SEO title="Confirmation payment" />
            <Header />
            <div className={"page"}>
                <h2 className={"page-title"}>Payment succeeded</h2>

                <p>
                    Thank you for your registration.</p>
                <div className={"col-lg-8 mx-auto mt-1"}>
                    <YourOrder ticket={ticket} options={options} vat={getVAT()} total={getTotal()} />
                </div>
                <p>
                    You will be receiving a confirmation email soon. Please check also your Junk folder.</p>
                <p>
                    <p>Union World Conference Registration Team </p>
                </p>
            </div>
            <div className={"content-footer footer-alone"}>
                <a href="https://union2022.urevent.fr/login" className={"sr-btn-next btn btn-info"} >Login</a>
            </div>
        </Layout>
    )
}

export default ConfirmationPaymentPage
